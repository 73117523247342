@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  @apply h-screen;
  @apply w-screen;
  @apply bg-futura-scuro;
}

::selection {
  background: #0284c7;
  color: #fff;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb:vertical {
  margin: 5px;
  background-color: #999;
  -webkit-border-radius: 5px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
  height: 5px;
  display: block;
}